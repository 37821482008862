import { BrowserRouter, Routes, Route } from "react-router-dom";
import AdminRoute from "./utils/routes/AdminRoute";
import CaptainRoute from "./utils/routes/CaptainRoute";
import AdminValidateRoute from "./utils/routes/AdminValidateRoute";
import CaptainValidateRoute from "./utils/routes/CaptainValidateRoute";
import PresentationValidateRoute from "./utils/routes/PresentationValidateRoute";

import NotificationConsole from "./pages/Notification/NotificationConsole";

import AdminConsole from "./pages/Admin/Console/AdminConsole";
import AdminLogin from "./pages/Admin/Login/AdminLogin";
import AdminSignup from "./pages/Admin/SignUp/AdminSignup";

import PresentationLogin from "./pages/Presentation/Login/PresentationLogin";
import PresentationConsole from "./pages/Presentation/Console/PresentationConsole";

import CaptainConsole from "./pages/Captain/Conosle/CaptainConsole";
import CaptainLogin from "./pages/Captain/Login/CaptainLogin";
import CaptainSignup from "./pages/Captain/SignUp/CaptainSignup";

import HomePage from "./pages/HomePage/HomePage";
import ValidatePage from "./pages/Validate/ValidatePage";
import InvalidPage from "./pages/Validate/InvalidPage";
import CurrentInOtherRoom from "./pages/Validate/CurrentInOtherRoom";
import AccessDeniedPage from "./pages/AccessDenied/AccessDeniedPage";
import NotFoundPage from "./pages/404/NotFoundPage";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route path="/notification/console/:role" element={<NotificationConsole/>} />
        <Route path="/invalid/:id" element={<InvalidPage/>} />
        <Route path="/invalid/other" element={<CurrentInOtherRoom/>}/>
        <Route path="/validate/:id" element={<ValidatePage/>}></Route>
        
        <Route path="/admin/signup" element={ <AdminSignup/>} />
        <Route path="/admin/login/:id" element={<AdminValidateRoute> <AdminLogin/> </AdminValidateRoute>} />
        <Route path="/admin/console/:id" element={<AdminRoute allowedRoles={['admin']}> <AdminConsole /> </AdminRoute> } />

        <Route path="/captain/signup" element={ <CaptainSignup/>} />
        <Route path="/captain/login/:id" element={ <CaptainValidateRoute> <CaptainLogin/> </CaptainValidateRoute>} />
        <Route path="/captain/console/:id" element={<CaptainRoute allowedRoles={['captain']}> <CaptainConsole /> </CaptainRoute> } />
        
        <Route path="/presentation/login/:id" element={<PresentationValidateRoute><PresentationLogin /></PresentationValidateRoute>}/>
        <Route path="/presentation/console/:id" element={<PresentationValidateRoute><PresentationConsole /></PresentationValidateRoute>}/>

        <Route path="/access-denied" element={<AccessDeniedPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;