import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { signInWithEmail, signUpWithEmail } from "../../apis/firebaseAPI";
import { checkAdminRole, checkCaptainRole, addDocument, signOut as logout, getAndSaveFCMToken, deleteToken } from "../../apis/firebaseAPI";
import { successNotification, errorNotification } from "../../utils/routes/Notification";
import { initializeAdminState } from "./gameAdminSlice";
import { initializeCaptainState } from "./gameCaptainSlice";
import { initializePresentationState } from "./gamePresentationSlice";
import { initializeValidState } from "./validSlice";
import Cookies from 'js-cookie';

import { addRole } from "../../utils/functions/addRole";
import { getRole } from "../../utils/functions/getRole";
import { deleteRole } from "../../utils/functions/deleteRole";
import { checkIsEmptyRole } from "../../utils/functions/checkIsEmptyRole";

export const signUpAsAdmin = createAsyncThunk(
  'firebase/sign_up_as_admin',
  async({email, name, password, password1}, {rejectWithValue}) => {
    try {
      await signUpWithEmail(email, password);
      await addDocument('admins',{email, name});
    } catch (error) {
      const errorCode = error.code;
      console.log('SignUp Error:', error);
      if(errorCode === 'auth/email-already-in-use') {
        return rejectWithValue('Email is already in Use.');
      }
      return rejectWithValue('Network Error');
    }
  }
)

export const signUpAsCaptain = createAsyncThunk(
  'firebase/sign_up_as_captain',
  async({email, name, password, password1}, {rejectWithValue}) => {
    try {
      await signUpWithEmail(email, password);
      await addDocument('captains',{email, name});
    } catch (error) {
      const errorCode = error.code;
      if(errorCode === 'auth/email-already-in-use') {
        return rejectWithValue('Email is already in Use');
      }
      return rejectWithValue('Network Error');
    }
  }
)

export const signInAsPlayer = createAsyncThunk(
  'firebase/sign_in_as_player',
  async ({ id }, { rejectWithValue }) => {
    // Check if the user exists in "admins" collection (replace 'email' with the relevant field)
    try {
      await Notification.requestPermission().then(async permission => {
        if (permission === 'granted') {
          console.log('Notification permission granted.');
          const fcmToken = await getAndSaveFCMToken(id, 'player');
          sessionStorage.setItem('fcmToken', fcmToken);
          Cookies.set('fcmToken', fcmToken);
          Cookies.remove('roundInfo');
          Cookies.remove('questionLimit');
          Cookies.remove('roundLimit');
          Cookies.remove('currentRound');
          Cookies.remove('currentQuestion');
        } else {
          console.log('Unable to get permission to notify.');
        }
      });
      return {
        isLogin: true,
        roomId: id
      }
    } catch(error) {
      return rejectWithValue(error.code + " : " + error.message);
      // if(error.code === "20") return rejectWithValue("Failed to get token. Try again!");
      // return rejectWithValue("Wrong Email or Password!");
    }
  }
);

export const signInAsAdmin = createAsyncThunk(
  'firebase/sign_in_as_admin',
  async ({ email, password, id }, { rejectWithValue }) => {
    try {
      // Check if the user exists in "admins" collection (replace 'email' with the relevant field)
      const data = await checkAdminRole(email, id);
      if (data.isAdmin) {
        try {
          await Notification.requestPermission().then(async permission => {
            if (permission === 'granted') {
              console.log('Notification permission granted.');
              const userCredential = await signInWithEmail(email, password);
              const fcmToken = await getAndSaveFCMToken(id, 'admin');
              // Cookies.set('role', 'admin*' + id);
              sessionStorage.setItem('fcmToken', fcmToken);
              Cookies.set('token', userCredential.user.accessToken);
              Cookies.set('isAuthenticated', true);
              Cookies.set('fcmToken', fcmToken);
              Cookies.remove('roundInfo');
              Cookies.remove('questionLimit');
              Cookies.remove('roundLimit');
              Cookies.remove('currentRound');
              Cookies.remove('currentQuestion');
            } else {
              console.log('Unable to get permission to notify.');
            }
          });
          return {
            isLogin: true,
            roomId: id,
            packageId: data.packageId
          }
        } catch(error) {
          console.log(error.message);
          return rejectWithValue(error.code + " : " + error.message);
          // if(error.code == "20") return rejectWithValue("Failed to get token. Try again!");
          // return rejectWithValue("Wrong Email or Password!");
        }
      } else {
        return {
          isLogin: false,
          roomId: id
        }
      }
    } catch (error) {
      console.error('Error signing in with email and password:', error);
      return rejectWithValue('Network Error!');
    }
  }
);

export const signInAsCaptain = createAsyncThunk(
  'firebase/sign_in_as_captain',
  async ({ email, password, id }, { rejectWithValue }) => {
    try {
      // Check if the user exists in "admins" collection (replace 'email' with the relevant field)
      const data = await checkCaptainRole(email, id);
      console.log(data);
      if (data.isCaptain) {
        try {
          await Notification.requestPermission().then(async permission => {
            if (permission === 'granted') {
              console.log('Notification permission granted.');
              const userCredential = await signInWithEmail(email, password);
              const fcmToken = await getAndSaveFCMToken(id, 'captain');
              sessionStorage.setItem('fcmToken', fcmToken);
              Cookies.set('fcmToken', fcmToken);
              Cookies.set('packageId', data.packageId);
              Cookies.set('token', userCredential.user.accessToken);
              Cookies.set('isAuthenticated', true);
            } else {
              console.log('Unable to get permission to notify.');
            }
          });
          return {
            isLogin: true,
            roomId: id,
            packageId: data.packageId
          }
        } catch(error) {
          console.log('Wrong Email or Password!:' + error);
          return rejectWithValue(error.code + " : " + error.message);
          // if(error.code == "20") return rejectWithValue("Failed to get token. Try again!");
          // return rejectWithValue("Wrong Email or Password!");
        }
      } else {
        return {
          isLogin: false,
          roomId: id
        }
      }
    } catch (error) {
      console.error('Error signing in with email and password:', error);
      return rejectWithValue('Network Error!');
    }
  }
);

export const signOut = createAsyncThunk(
  'firebase/sign_out',
  async({roomId, role}, { dispatch }) => {
    await logout();
    console.log(Cookies.get('role'));
    // console.log(Cookies.get('fcmToken'));
    // console.log(Cookies.get('roomId'));
    await deleteToken(roomId,sessionStorage.getItem('fcmToken'),role);
    if(role.startsWith('admin')) {
      dispatch(initializeAdminState());
    } else if (role.startsWith('captain')) {
      dispatch(initializeCaptainState());
    } else {
      dispatch(initializePresentationState());
    }
    dispatch(initializeValidState());
    sessionStorage.removeItem('fcmToken');
    // Cookies.set('role', 'none');
    // Cookies.remove('role');
    deleteRole(role + '*' + roomId);
    Cookies.set('token', '')
    Cookies.set('isAuthenticated', false);
    Cookies.set('start', false);
    Cookies.remove('fcmToken');
    Cookies.remove('packageId');
    Cookies.remove('roundInfo');

    Cookies.remove('attempt');
    if(checkIsEmptyRole()) {
      Cookies.remove('roomId');
    }
    // Cookies.remove('questionLimit');
    // Cookies.remove('roundLimit');
    // Cookies.remove('currentRound');
    // Cookies.remove('currentQuestion');
    
  }
)

const authSlice = createSlice({
  name: "auth",
  initialState: { user: "", login: false, status: "idle", isAuthenticated: Cookies.get("isAuthenticated") || false, role: getRole(), error: null, loading: false },
  reducers: {
  
  },
  extraReducers: (builder) => {
    builder
    .addCase(signUpAsAdmin.pending, (state) => {
      state.status = "loading";
      state.loading = true;
    })
    .addCase(signUpAsAdmin.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.loading = false;
      state.isAuthenticated = true;
      successNotification("Success", "Your account is created successfully!");
    })
    .addCase(signUpAsAdmin.rejected, (state, action) => {
      state.status = "failed";
      state.loading = false;
      errorNotification("Error!", action.payload);
    })
    .addCase(signUpAsCaptain.pending, (state) => {
      state.status = "loading";
      state.loading = true;
    })
    .addCase(signUpAsCaptain.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.loading = false;
      state.isAuthenticated = true;
      successNotification("Success", "Your account is created successfully!");
    })
    .addCase(signUpAsCaptain.rejected, (state, action) => {
      state.status = "failed";
      state.loading = false;
      errorNotification("Error!", action.payload);
    })
    .addCase(signInAsAdmin.pending, (state) => {
      state.status = "loading";
      state.loading = true;
    })
    .addCase(signInAsAdmin.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.loading = false;
      state.isAuthenticated = true;
      if(action.payload.isLogin) {
        successNotification("Success", "Successfully logged in!");
        // Cookies.set('role', 'admin*' + action.payload.roomId);
        addRole('admin*' + action.payload.roomId);
        Cookies.set('packageId', action.payload.packageId);
        Cookies.set('isAuthenticated', true);
        console.log("getRole : ", getRole())
        // state.role = "admin*" + action.payload.roomId;
        state.role = getRole();        
      }
      else errorNotification("Error!", "Wrong Email or Password!");
    })
    .addCase(signInAsAdmin.rejected, (state, action) => {
      state.status = "failed";
      state.loading = false;
      errorNotification("Error!", action.payload);
    })
    .addCase(signInAsCaptain.pending, (state) => {
      state.status = "loading";
      state.loading = true;
    })
    .addCase(signInAsCaptain.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.loading = false;
      state.isAuthenticated = true;
      if(action.payload.isLogin) {
        successNotification("Success", "Successfully logged in!");
        addRole('captain*' + action.payload.roomId);
        Cookies.set('packageId', action.payload.packageId);
        Cookies.set('isAuthenticated', true);
        state.role = getRole();  
      }
      else errorNotification("Error!", "Wrong Email or Password!");
    })
    .addCase(signInAsCaptain.rejected, (state, action) => {
      state.status = "failed";
      state.loading = false;
      errorNotification("Error!", action.payload);
    })
    .addCase(signInAsPlayer.pending, (state) => {
      state.status = "loading";
      state.loading = true;
    })
    .addCase(signInAsPlayer.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.loading = false;
      if(action.payload.isLogin) {
        addRole('player*' + action.payload.roomId);
        state.role = getRole();  
        successNotification("Success");
        // if (navigator.serviceWorker.controller) {
        //   navigator.serviceWorker.controller.postMessage({
        //     packageId: action.payload.packageId,
        //     role: 'player'
        //   });
        // }
      }
      else errorNotification("Error!", "Failed to get Token! Try again!");
    })
    .addCase(signInAsPlayer.rejected, (state, action) => {
      state.status = "failed";
      state.loading = false;
      errorNotification("Error!", "Failed to get Token! Try again!");
    })
    .addCase(signOut.pending, (state) => {
      state.status = "loading";
      state.loading = true;
    })
    .addCase(signOut.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.loading = false;
      state.isAuthenticated = false;
      state.role = getRole();
      successNotification("Success", "You logged out!");
    })
    .addCase(signOut.rejected, (state, action) => {
      state.status = "failed";
      state.loading = false;
      errorNotification("Error!", action.payload);
    })
  },
});

export const {} = authSlice.actions;

export default authSlice.reducer;