import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { Spin } from 'antd';
import { signInAsPlayer } from '../../redux/features/authSlice';
import { checkRoomId } from '../../redux/features/validSlice';

const DotAnimation = () => {
  const [dots, setDots] = useState('');
  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 3 ? prevDots + '.' : ''));
    }, 500); // Change dot count every 500ms

    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);

  return <span style={{ marginLeft: '5px', width: '20px', display: 'inline-block', textAlign: 'left' }}>{dots}</span>;
};

const ValidatePage = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const attempt = Cookies.get('attempt');

  const validInfo = useSelector(state => state.valid);

  useEffect(() => {
    dispatch(checkRoomId({id: id}));
  }, [])

  useEffect(() => {
    if (validInfo.roomId === id) {
      if(attempt) {
        navigate(`/${attempt}/login/${id}`);
      } else {
        // dispatch(signInAsPlayer({id: id}));
        navigate(`/presentation/login/${id}`);
      }
    }
    if (validInfo.roomId === id + '***') {
      navigate(`/invalid/${id}`);
    }
    if (validInfo.roomId === 'neterror***') {
      navigate(`/invalid/${id}`);
    }
  }, [validInfo.roomId, navigate, id, attempt]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
      }}
    >
      <Spin size="large" />
      <div
        style={{
          marginTop: '20px',
          color: 'white',
          fontSize: '16px',
        }}
      >
        Validating Room ID<DotAnimation />
      </div>
    </div>
  );
};

export default ValidatePage;
