import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBIcon
}
from 'mdb-react-ui-kit';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import { signUpAsAdmin } from '../../../redux/features/authSlice';

import { checkEmailValid } from '../../../utils/functions/checkEmailValid';

import './AdminSignup.css'

const AdminSignup = () => {
  const dispatch = useDispatch();
  const authInfo = useSelector(state => state.auth);

  const [userInfo, setUserInfo] = useState({email: "", name: "", password: "", password1: "",});
  const [emailCheck, setEmailCheck] = useState(true);
  const [nameCheck, setNameCheck] = useState(true);
  const [passwordCheck, setPasswordCheck] = useState(true);
  const [passwordComp, setPasswordComp] = useState(true);

  const checkUserInfo = () => {
    if(!checkEmailValid(userInfo.email)) {
      setEmailCheck(false);
      return false;
    }
    setEmailCheck(true);
    if(userInfo.name === "") {
      setNameCheck(false);
      return false;
    }
    setNameCheck(true);
    if(userInfo.password === "") {
      setPasswordCheck(false);
      return false;
    }
    setPasswordCheck(true);
    if(userInfo.password !== userInfo.password1) {
      setPasswordComp(false);
      return false;
    }
    setPasswordComp(true);
    return true;
  }

  const handleSignUp = () => {
    if(!checkUserInfo()) return;
    dispatch(signUpAsAdmin(userInfo));
  }


  return (
    <MDBContainer fluid className='admin-signup-body'>

      <MDBRow className='d-flex justify-content-center align-items-center h-100'>
        <MDBCol col='12'>

          <MDBCard className='bg-dark text-white my-5 mx-auto' style={{borderRadius: '1rem', maxWidth: '400px'}}>
            {authInfo.loading && (
              <Box sx={{ width: '100%' }}>
                <LinearProgress />
              </Box>
            )}
            <div >
              <p className="mb-0" style= {{float: 'right', margin: '10px'}}> <Link to="/" className="text-white-50 fw-bold"><MDBIcon fab icon='home' size="lg"/></Link></p>
            </div>
            <MDBCardBody className='p-5 d-flex flex-column align-items-center mx-auto w-100'>

              <h2 className="fw-bold mb-2 text-uppercase">Admin Signup</h2>
              <p className="text-white-50 mb-5">Please enter your account info!</p>

              <MDBInput wrapperClass='mb-4 mx-5 w-100' labelClass='text-white' label='Email address' id='formControlLg' type='email' size="lg" style={{color: 'white'}} value={userInfo.email} onChange={(e) => setUserInfo({...userInfo, email: e.target.value})} />
              {!emailCheck && (<p className='signup-error-message'>*Please enter your email correctly!</p>) }

              <MDBInput wrapperClass='mb-4 mx-5 w-100' labelClass='text-white' label='Name' id='formControlLg' type='name' size="lg" style={{color: 'white'}} value={userInfo.name} onChange={(e) => setUserInfo({...userInfo, name: e.target.value})} />
              {!nameCheck && (<p className='signup-error-message'>*Please enter your name correctly!</p>) }

              <MDBInput wrapperClass='mb-4 mx-5 w-100' labelClass='text-white' label='Password' id='formControlLg' type='password' size="lg" style={{color: 'white'}} value={userInfo.password} onChange={(e) => setUserInfo({...userInfo, password: e.target.value})} />
              {!passwordCheck && (<p className='signup-error-message'>*Password must be entered!</p>) }

              <MDBInput wrapperClass='mb-4 mx-5 w-100' labelClass='text-white' label='Confirm Password' id='formControlLg' type='password' size="lg" style={{color: 'white'}} value={userInfo.password1} onChange={(e) => setUserInfo({...userInfo, password1: e.target.value})}/>
              {!passwordComp && (<p className='signup-error-message'>*Passwords don't match each other!</p>) }

              <MDBBtn outline className='mx-2 px-5 mb-3' color='white' size='lg' onClick={handleSignUp}>
                Sign up
              </MDBBtn>

              <div>
                <p className="mb-0">Already have an account? <Link to="/" className="text-white-50 fw-bold"><u>Home</u></Link></p>

              </div>
            </MDBCardBody>
          </MDBCard>

        </MDBCol>
      </MDBRow>

    </MDBContainer>
  );
}

export default AdminSignup;