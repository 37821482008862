import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { errorNotification } from "../../utils/routes/Notification";
import Cookies from 'js-cookie';
import axios from 'axios';

import { serverUrl } from "../../config/config";
import { parseInfo } from "../../utils/functions/parseInfo";

export const getQuestion = createAsyncThunk(
  'presentation/get_question',
  async({roomId}, {rejectWithValue}) => {
    try {
      console.log(roomId);
      const response = await axios.post(`${serverUrl}/presentation/get_question`, {roomId: roomId});
      return response.data;
    } catch (error) {
      console.log('Get Question',error.code, error.message);
      return rejectWithValue(error.message);
    }
  }
)

const gamePresentationSlice = createSlice({
  name: "gamePresentation",
  initialState: { status: "idle", error: null, loading: false, packageId: Cookies.get('packageId'), question:"", question_image: "", questionLimit: [0], roundLimit: 0, currentRound: -1, currentQuestion: -1, start: false,},
  reducers: {
    initializePresentationState: (state) => {
      state.roundLimit = 0;
      state.questionLimit = [0];
      state.currentRound = -1;
      state.currentQuestion = -1;
      state.question = "";
      state.question_image = "";
      state.packageId = "";
      state.status = "idle";
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(getQuestion.pending, (state) => {
      state.status = "loading";
      state.loading = true;
    })
    .addCase(getQuestion.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.loading = false;
      state.start = action.payload.status;
      if(state.start) {
        state.question = action.payload.question;
        state.question_image = action.payload.question_image;
        state.roundLimit = action.payload.roundLimit;
        state.questionLimit = action.payload.questionLimit;
        state.currentQuestion = parseInfo(action.payload.info).questionIndex;
        state.currentRound = parseInfo(action.payload.info).roundIndex;
      } else {
        state.roundLimit = 0;
        state.questionLimit = [0];
        state.currentRound = -1;
        state.currentQuestion = -1;
        state.question = "";
        state.question_image = "";
        state.packageId = "";
        state.status = "idle";
      }
    })
    .addCase(getQuestion.rejected, (state, action) => {
      state.status = "failed";
      state.loading = false;
      errorNotification("Error!", action.payload);
    })
  },
});

export const {initializePresentationState} = gamePresentationSlice.actions;

export default gamePresentationSlice.reducer;