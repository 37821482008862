import Cookies from "js-cookie";

export const checkIsEmptyRole = () => {
  let roleValue = Cookies.get('role');
  let roleArray = [];
  if(roleValue) {
    roleArray = JSON.parse(roleValue);
    if(roleArray.length === 0) return true;
  } else {
    return true;
  }
  return false;
}