import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, TextField, Typography } from '@mui/material';
import { Layout, Modal } from 'antd';
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Loading from '../../../utils/Loading';

import { onMessageListener } from '../../../apis/firebaseAPI';

import { signOut } from '../../../redux/features/authSlice';
import { getQuestion } from '../../../redux/features/gameCaptainSlice';

import './CaptainConsole.css';
const { Header, Content, Sider, footer } = Layout;

const CaptainConsole = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {id} = useParams();

  const [anchorElUser, setAnchorElUser] = useState(null);
  const [isVisible, setIsVisible] = useState(!document.hidden);
  const [answer, setAnswer] = useState('');

  const authInfo = useSelector(state => state.auth);
  const gameCaptain = useSelector(state => state.gameCaptain);

  const [isQuitModal, setIsQuitModal] = useState(false);

  const handleQuit = () => {
    dispatch(signOut({roomId: id, role: 'captain'}));
  };

  useEffect(() => {
    dispatch(getQuestion({roomId: id}));
  }, [])

  useEffect(() => {
    if (!authInfo.role.includes('captain*' + id)) {
      navigate("/");
    }
  }, [authInfo.role, navigate, id]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };
    // Event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const subscribeToMessages = async () => {
    try {
      const payload = await onMessageListener();
      console.log('Message received. ', payload);
      // Handle the message
      if (payload.data.end === 'true') {
        console.log('end');
        setIsQuitModal(true);
      } else {
        dispatch(getQuestion({ roomId: id }));
      }
    } catch (err) {
      console.log('Failed to receive message', err);
    }
  };

  useEffect(() => {
    let isSubscribed = true;
    if(isVisible) {
      if(isSubscribed) {
        subscribeToMessages();
      }
    }
    return () => {
      console.log("unsubscribe");
      isSubscribed = false; // Unsubscribe when the component is unmounted
    };
  });

  // onMessageListener().then(payload => {
  //   console.log(payload);
  // }).catch(err => console.log('failed: ', err));

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleGoHome = () => {
    navigate("/");
  }

  const handleLogout = () => {
    dispatch(signOut({roomId: id, role: 'captain'}));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(`Answer for question`);
    setAnswer('');
  };

  return (
    <>
      {gameCaptain.loading && <Loading/>}
     <Layout className="captain-body">
     <Header
        className="captain-header"
      >
         <Box sx={{ flexGrow: 1 }}>
            <Tooltip title="Profile">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, float: "right" }}>
                    <Icon sx={{color: 'white', fontSize: 32}}>account_circle</Icon>
                </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px"}}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {/* <MenuItem key="home" onClick={handleGoHome}>
                  <Typography textAlign="center">Home</Typography>
              </MenuItem> */}
              <MenuItem key="logout" onClick={handleLogout}>
                  <Typography textAlign="center">LogOut</Typography>
              </MenuItem>
            </Menu>
          </Box>
      </Header>
      <Layout>
        <Sider
          // width={200}
          className="captain-sider"
        >
            <h3 className='captain-game-info'>&nbsp;&nbsp;&nbsp;&nbsp;Round {gameCaptain.currentRound + 1}</h3>
            <h3 className='captain-game-info'>Question {gameCaptain.currentQuestion + 1}</h3>
        </Sider>
        <Layout
          style={{
            padding: '0 24px 24px',
          }}
        >
          <Content className="captain-content">
            <Typography variant="h5" className="question">
              {gameCaptain.question ? gameCaptain.question : "Question"}
            </Typography>
            <div className="question-image-div">
              <img src={gameCaptain.question_image} alt="Question img" className="question-image"/>
            </div>
            <div className="answer-section">
            <TextField
              variant="outlined"
              size="small"
              style={{ marginRight: '16px' }}
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
              placeholder="Type your answer here..."
            />
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </div>

            <Modal
              title="Game Over"
              open={isQuitModal}
              closeIcon={false}
              footer={[
                <Button key="submit" type="primary" onClick={handleQuit}>
                  QUIT
                </Button>
              ]}
            >
              The Game is over. Your team's score is: 0.
            </Modal>
          </Content>
        </Layout>
      </Layout>
    </Layout>
      
    </>
  );
};

export default CaptainConsole;