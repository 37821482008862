import React from "react";
import { useNavigate } from "react-router-dom";

import "./AccessDeniedPage.css";

const AccessDeniedPage = () => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1); // This will navigate the user back to their previous page.
    };

    return (
        <div className="access-denied-container">
            <h1>Access Denied!</h1>
            <h2>You don't have permission</h2>
            <p>Sorry, you don't have the necessary permissions to view this content. Please ensure you're logged in with the appropriate credentials or contact an administrator for assistance.</p>
            <button className="return-button" onClick={handleGoBack}>
                Return to Previous Page
            </button>
        </div>
    );
}

export default AccessDeniedPage;