import { Button, Result } from 'antd';
import { useNavigate, useParams } from 'react-router-dom'; // if you're using react-router
import './InvalidPage.css'

const InvalidPage = () => {
  const {id} = useParams();
  const navigate = useNavigate();

  const handleRetry = () => {
    navigate(`/validate/${id}`);
    console.log('Retrying...'); // Implement retry logic here
  };

  const handleGoBack = () => {
    navigate("/"); // Implement go-back logic here
  };

  return (
    <div
      className = "invalid-body"
    >
      <Result
        status="error"
        title={<span style={{ color: '#fff' }}>Invalid Room ID</span>} // Ensure title text is also white
        subTitle={
          <span style={{ color: '#fff' }}>
            Sorry, the room ID you entered does not exist or is invalid.
          </span>
        }
        extra={[
          <Button type="primary" key="goBack" onClick={handleGoBack}>
            Go Back
          </Button>,
          <Button key="tryAgain" onClick={handleRetry}>
            Try Again
          </Button>,
        ]}
      />
    </div>
  );
};

export default InvalidPage;