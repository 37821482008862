import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc, deleteDoc, getDoc, getDocs, query, where, doc, setDoc, updateDoc, arrayUnion, arrayRemove} from 'firebase/firestore';
import { getDatabase, ref, get, onValue, update as updateRTDB, push, set } from 'firebase/database';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut as firebaseSignOut } from 'firebase/auth';
import { getToken, onMessage, getMessaging} from 'firebase/messaging';
// import { getMessaging as getMessagingInSw} from 'firebase/messaging/sw';

const firebaseConfig_test = {
  apiKey: "AIzaSyAj0WmLauVMLtQ4-OTsWNJ7Z-dgRi-Jhxc",
  authDomain: "brainium-firebase-test.firebaseapp.com",
  databaseURL: "https://brainium-firebase-test-default-rtdb.firebaseio.com",
  projectId: "brainium-firebase-test",
  storageBucket: "brainium-firebase-test.appspot.com",
  messagingSenderId: "36367126826",
  appId: "1:36367126826:web:6088329df52c206ab84e53",
  measurementId: "G-N9ZJGE055Y"
};

const firebaseConfig_qa = {
  apiKey: "AIzaSyA-gL4s0Vq4xg_DenJMRby_kyZiP17sCAU",
  authDomain: "brainium-qa.firebaseapp.com",
  projectId: "brainium-qa",
  storageBucket: "brainium-qa.appspot.com",
  messagingSenderId: "307207499365",
  appId: "1:307207499365:web:fbcfcdd581da421348e08c"
};

const app = initializeApp(firebaseConfig_qa);
const db = getFirestore(app);
const realtimeDb = getDatabase(app);
const auth = getAuth(app);
const messaging = getMessaging(app);
// const messaging = getMessagingInSw(app);

const getAndSaveFCMToken = async (roomId, role) => {
  try {
    const currentToken = await getToken(messaging, { vapidKey: 'BFwCQhRHJR3f_qHjJC3435yzXvO-NKRK28v0gPhvsraUuT3Xe22SkW8r-wWCkqub_o62dK1iaEAaEocIweIlpIw' });
    
    if (currentToken) {
      // const userDoc = doc(db, 'users', userId);
      // await setDoc(userDoc, { fcmToken: currentToken }, { merge: true });
      const docRef = doc(db, 'rooms', roomId);
      if(role === 'admin') {
        await updateDoc(docRef, { admin_token: currentToken });
      } else if (role === 'captain') {
        await updateDoc(docRef, { captain_tokens: arrayUnion(currentToken)});
      } else {
        await updateDoc(docRef, { player_tokens: arrayUnion(currentToken)});
      }
      console.log(currentToken);
      return currentToken;
    } else {
      console.log('No registration token available. Request permission to generate one.');
      // Show permission UI or handle the lack of a token.
      return '';
    }
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err);
    // Handle error, possibly by returning null or re-throwing the error
    throw err;
  }
};

// onMessage(messaging, (payload) => {
//   console.log('Message received.', payload);
//   // alert(payload);
// })

const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

const deleteToken = async (roomId, token, role) => {
  try {
    // const userDoc = doc(db, 'users', userId);
    // await setDoc(userDoc, { fcmToken: currentToken }, { merge: true });
    const docRef = doc(db, 'rooms', roomId);
    if(role.startsWith('admin')) {
      await updateDoc(docRef, { admin_token: '' });
    } else if(role.startsWith('captain')) {
      await updateDoc(docRef, { captain_tokens: arrayRemove(token)});
    } else {
      await updateDoc(docRef, { player_tokens: arrayRemove(token)});
    }
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err);
    // Handle error, possibly by returning null or re-throwing the error
    throw err;
  }
};

// Firestore API
const getCollection = async (collectionName) => {
  const querySnapshot = await getDocs(collection(db, collectionName));
  return querySnapshot.docs.map((doc) => ({
    id: doc.id,
    data: doc.data()
  }));;
};

const getDocsFromCollectionWithCondition = async (collectionName, field, condition, value) => {
  try {
    const q = query(collection(db, collectionName), where(field, condition, value));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data()
    }));;
  } catch (error) {
    console.error('Error fetching collection with condition:', error);
    return new Error("Network Error!");
  }
};

const addDocument = async (collectionName, documentData) => {
  const collectionRef = collection(db, collectionName);
  const docRef = await addDoc(collectionRef, documentData);
  console.log('Document added with ID: ', docRef.id);
  return docRef.id;
};

const addDocuments = async (collectionName, documents) => {
  const collectionRef = collection(db, collectionName);
  const batch = [];

  // Generate a new ID for each document
  documents.forEach((document) => {
    const docRef = doc(collectionRef);
    const newDocument = { ...document, id: docRef.id };
    const promise = setDoc(docRef, newDocument);
    batch.push(promise);
  });

  await Promise.all(batch);
  console.log('Documents added successfully!');
};

const checkDocumentId = async (collectionName, documentId) => {
  const docRef = doc(db, collectionName, documentId);
  const docSnap = await getDoc(docRef);
  return(docSnap.exists());
}

const deleteDocument = async (collectionName, documentId) => {
  const docRef = doc(db, collectionName, documentId);
  await deleteDoc(docRef);
  console.log('Document deleted successfully!');
};

const deleteDocuments = async (collectionName, documentIds) => {
  const collectionRef = collection(db, collectionName);

  for (const documentId of documentIds) {
    const docRef = doc(collectionRef, documentId);
    await deleteDoc(docRef);
    console.log(`Document ${documentId} deleted successfully!`);
  }
};

// const checkAdminRole = async (email) => {
//   try {
//     const q = query(collection(db, 'admins'), where('email', '==', email));
//     const querySnapshot = await getDocs(q);
//     const result = querySnapshot.docs.map(doc => doc.data());
//     console.log(result);
//     if(result.length > 0) {
//       return true;
//     }
//     return false;
//   } catch(error) {
//     console.log(error);
//     new Error("Network Error!")
//   }
// }

const checkAdminRole = async (email, id) => {
  try {
    const docRef = doc(db, "rooms", id);
    const docSnap = await getDoc(docRef);
    if(docSnap.exists()) {
      const data = docSnap.data();
      if(data.admin === email) {
        return {
          isAdmin: true,
          packageId: data.packageId
        };
      }
      return {
        isAdmin: false,
        packageId: null
      };
    } else {
      return {
        isAdmin: false,
        packageId: null
      };
    }
  } catch (error) {
    console.log(error);
    new Error("Network Error!");
  }
}

// const checkCaptainRole = async (email, id) => {
//   const q = query(collection(db, 'captains'), where('email', '==', email));
//   try {
//     const querySnapshot = await getDocs(q);
//     const result = querySnapshot.docs.map(doc => doc.data());
//     if(result.length > 0) {
//       return true;
//     }
//     return false;
//   } catch(error) {
//     console.log(error);
//     new Error("Network Error!")
//   }
// }

const checkCaptainRole = async (email, id) => {
  try {
    const docRef = doc(db, "rooms", id);
    const docSnap = await getDoc(docRef);
    if(docSnap.exists()) {
      const data = docSnap.data();
      if(data.captains.includes(email)) {
        return {
          isCaptain: true,
          packageId: data.packageId
        };
      }
      return {
        isCaptain: false,
        packageId: data.null
      };
    } else {
      return {
        isCaptain: false,
        packageId: null
      };
    }
  } catch (error) {
    console.log(error);
    new Error("Network Error!");
  }
}

// Realtime Database API
const getRealtimeData = async (reference) => {
  const dataSnapshot = await get(ref(realtimeDb, reference));
  return dataSnapshot.val();
};

const listenToRealtimeData = (reference, callback) => {
  const dataRef = ref(realtimeDb, reference);

  const unsubscribe = onValue(dataRef, (snapshot) => {
    const data = snapshot.val();
    callback(data);
  }, {
    onlyOnce: false,
  });

  return unsubscribe;
};

const getLimitedRealtimeData = async (reference, limit, direction = "first") => {
  const dataRef = ref(realtimeDb, reference);
  if(direction == "first") {
    const dataSnapshot = await get(dataRef.limitToFirst(limit));
    return dataSnapshot.val();
  } else {
    const dataSnapshot = await get(dataRef.limitToLast(limit));
    return dataSnapshot.val();
  }
};

const updateRealtimeData = async (updates) => {
  await updateRTDB(ref(realtimeDb), updates);  // Using the update function
  console.log("Data updated successfully!");
}

const addRealtimeData = async (reference, data) => {
  const dataRef = ref(realtimeDb, reference);

  // Add data using push() method to generate a unique key
  const newRef = push(dataRef);
  const newKey = newRef.key;
  const newData = data;

  // Set the new data at the generated key
  await set(newRef, newData);
}

// Authentication API
const signInWithEmail = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

const signUpWithEmail = (email, password) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

const signOut = () => {
  console.log("sign out")
  return firebaseSignOut(auth);
};

export {
  getAndSaveFCMToken,
  onMessageListener,
  deleteToken,
  getCollection,
  getDocsFromCollectionWithCondition,
  addDocument,
  addDocuments,
  deleteDocument,
  deleteDocuments,
  checkDocumentId,
  checkAdminRole,
  checkCaptainRole,
  getRealtimeData,
  listenToRealtimeData,
  getLimitedRealtimeData,
  updateRealtimeData,
  addRealtimeData,
  signInWithEmail,
  signUpWithEmail,
  signOut,
};