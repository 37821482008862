import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBIcon
}
from 'mdb-react-ui-kit';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import { signInAsCaptain } from '../../../redux/features/authSlice';

import './CaptainLogin.css'

const CaptainLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {id} = useParams();

  const authInfo = useSelector((state) => state.auth);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (authInfo.role.includes('captain*' + id)) {
      navigate(`/captain/console/${id}`);
    } 
  }, [authInfo.role, navigate, id]);

  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(signInAsCaptain({email: email, password: password, id: id}));
  }

  return (
    <MDBContainer fluid className='captain-login-body'>

      <MDBRow className='d-flex justify-content-center align-items-center h-100'>
        <MDBCol col='12'>
          
          <MDBCard className='bg-dark text-white my-5 mx-auto' style={{borderRadius: '1rem', maxWidth: '400px'}}>
            {authInfo.loading && (
              <Box sx={{ width: '100%' }}>
                <LinearProgress />
              </Box>
            )}
            <div >
              <p className="mb-0" style= {{float: 'right', margin: '10px'}}> <Link to="/" className="text-white-50 fw-bold"><MDBIcon fab icon='home' size="lg"/></Link></p>
            </div>
            <MDBCardBody className='p-5 d-flex flex-column align-items-center mx-auto w-100'>

              <h2 className="fw-bold mb-2 text-uppercase">Captain Login</h2>
              <p className="text-white-50 mb-5">Please enter your login and password!</p>

              <MDBInput wrapperClass='mb-4 mx-5 w-100' style={{color: 'white'}} labelClass='text-white' label='Email address' id='email' type='email' size="lg" value={email} onChange={(e) => setEmail(e.target.value)}/>
              <MDBInput wrapperClass='mb-4 mx-5 w-100' style={{color: 'white'}} labelClass='text-white' label='Password' id='passwird' type='password' size="lg" value={password} onChange={(e) => setPassword(e.target.value)} />

              <p className="small mb-3 pb-lg-2"><a className="text-white-50" href="#!">Forgot password?</a></p>
              <MDBBtn outline className='mx-2 px-5' color='white' size='lg' onClick={handleLogin} disabled={authInfo.loading}>
                Login
              </MDBBtn>

              <div className='d-flex flex-row mt-3 mb-3'>
                <MDBBtn tag='a' color='none' className='m-3' style={{ color: 'white' }}>
                  <MDBIcon fab icon='google' size="lg"/>
                </MDBBtn>
              </div>

              <div>
                <p className="mb-0">Don't have an account? <Link to="/captain/signup" className="text-white-50 fw-bold">Sign Up</Link></p>

              </div>
            </MDBCardBody>
          </MDBCard>

        </MDBCol>
      </MDBRow>

    </MDBContainer>
  );
}

export default CaptainLogin;