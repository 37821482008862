import { notification } from 'antd';

const duration = 2.5;

const successNotification = (title, description) => {
    notification.success({
        message: title,
        description: description,
        duration: duration,
    })
}

const errorNotification = (title, description) => {
    notification.error({
        message: title,
        description: description,
        duration: duration,
    })
}

const warningNotification = (title, description) => {
    notification.warning({
        message: title,
        description: description,
        duration: duration,
    })
}

const infoNotification = (title, description) => {
    notification.info({
        message: title,
        description: description,
        duration: duration,
    })
}

export {successNotification, infoNotification, errorNotification, warningNotification};
