import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import {
  MDBContainer,
}
from 'mdb-react-ui-kit';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import { signInAsPlayer } from '../../../redux/features/authSlice';
import Loading from '../../../utils/Loading';

import './PresentationLogin.css'

const PresentationLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {id} = useParams();
  
  const authInfo = useSelector((state) => state.auth);

  useEffect(() => {
    if (authInfo.role.includes('player*' + id)) {
      navigate(`/presentation/console/${id}`);
    } 
  }, [authInfo.role, navigate, id]);

  const handleGo = () => {
    dispatch(signInAsPlayer({id: id}));
  }

  const handleGoHome = () => {
    navigate("/");
  }

  return (
    <>
      {authInfo.loading && <Loading/>}
      <MDBContainer fluid className='presentation-login-body'>
        <Stack spacing={2} direction="row" className="presentation-login-button-group">
          <Button variant="contained" onClick={handleGoHome} sx={{backgroundColor: "brown"}}>GO TO HOME</Button>
          <Button variant="contained" onClick={handleGo}>GO TO CONSOLE</Button>
        </Stack>
      </MDBContainer>
    </>
    
  );
}

export default PresentationLogin;