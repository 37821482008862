import React from "react";
import { useNavigate } from "react-router-dom";

import "./NotFoundPage.css";

const NotFoundPage = () => {
    const navigate = useNavigate();

    const handleGoHome = () => {
        navigate('/');
    };

    return (
        <div className="not-found-container">
            <h1>404</h1>
            <h2>Page Not Found</h2>
            <p>We couldn't find the page you were looking for.</p>
            <button className="back-to-home" onClick={handleGoHome}>
                Go to Home Page
            </button>
        </div>
    );
}

export default NotFoundPage;