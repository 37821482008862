import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';


const NotificationConsole = () => {
  let {role} = useParams();
  const navigate = useNavigate();
  // let role = Cookies.get('role').split("*")[0];
  const roomId = Cookies.get('roomId');
  if(role === 'player') role = 'presentation';
  useEffect(() => {
    navigate(`/${role}/console/${roomId}`);
  }, [])

  return (
    <>
    </>
  );
};

export default NotificationConsole;