import React from "react";
import { useNavigate } from "react-router-dom";

import "./HomePage.css"
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

const HomePage = () => {
    const navigate = useNavigate();

    return (
        <div className="home-body">
            <Stack spacing={2} direction="row" className="home-button-group">
                <h3 style = {{color: 'purple'}}>Room 1</h3>
                <Button variant="contained" onClick={() => navigate("/presentation/login/5RjqSrB91tc8wqcT2git")}>Presentation Console</Button>
                <Button variant="contained" onClick={() => navigate("/admin/login/5RjqSrB91tc8wqcT2git")}>Admin Console</Button>
                <Button variant="contained" onClick={() => navigate("/captain/login/5RjqSrB91tc8wqcT2git")}>Captain Console</Button>
            </Stack>
            <Stack spacing={2} direction="row" className="home-button-group">
                <h3 style = {{color: 'purple'}}>Room 2</h3>
                <Button variant="contained" onClick={() => navigate("/presentation/login/xQK326KpQSqLGrwaHxyR")}>Presentation Console</Button>
                <Button variant="contained" onClick={() => navigate("/admin/login/xQK326KpQSqLGrwaHxyR")}>Admin Console </Button>
                <Button variant="contained" onClick={() => navigate("/captain/login/xQK326KpQSqLGrwaHxyR")}>Captain Console</Button>
            </Stack>
            <Stack spacing={2} direction="row" className="home-button-group">
                <h3 style = {{color: 'purple'}}>Room 3</h3>
                <Button variant="contained" onClick={() => navigate("/presentation/login/2EPGpN1LH74A2b5v1jwO")}>Presentation Console</Button>
                <Button variant="contained" onClick={() => navigate("/admin/login/2EPGpN1LH74A2b5v1jwO")}>Admin Console</Button>
                <Button variant="contained" onClick={() => navigate("/captain/login/2EPGpN1LH74A2b5v1jwO")}>Captain Console</Button>
            </Stack>
        </div>
    )
}

export default HomePage;