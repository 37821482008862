import Cookies from "js-cookie";

export const addRole = (role) => {
  let roleValue = Cookies.get('role');
  let roleArray = [];
  if(roleValue) {
    roleArray = JSON.parse(roleValue);
  }
  roleArray.push(role);
  Cookies.set('role', JSON.stringify(roleArray));
}