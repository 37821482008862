import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Typography } from '@mui/material';
import { Layout, theme } from 'antd';
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";

import Loading from '../../../utils/Loading';
import { startGame, getQuestion, endGame, nextQuestion } from '../../../redux/features/gameAdminSlice';

import { signOut } from '../../../redux/features/authSlice';

import './AdminConsole.css';
const { Header, Content, Footer, Sider } = Layout;

const AdminConsole = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {id} = useParams();

  const authInfo = useSelector(state => state.auth);
  const gameAdmin = useSelector(state => state.gameAdmin);
  const [anchorElUser, setAnchorElUser] = useState(null);   

  useEffect(() => {
    dispatch(getQuestion({roomId: id}));
  }, []);

  useEffect(() => {
    if (!authInfo.role.includes('admin*' + id)) {
      navigate("/");
    } 
  }, [authInfo.role, navigate, id]);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
      setAnchorElUser(null);
  };
  const handleGoHome = () => {
    navigate("/");
  }

  const handleLogout = () => {
    console.log("logout");
    dispatch(signOut({roomId: id, role: 'admin'}));
  }
  
  const handleStart = () => {
    dispatch(startGame({roomId: id}));
  }

  const handleEnd = () => {
    dispatch(endGame({roomId: id}));
  }

  const handleNextQuestion = () => {
    dispatch(nextQuestion({roomId: id, roundIndex: gameAdmin.currentRound, questionIndex: gameAdmin.currentQuestion + 1}));
  };

  const handleNextRound = () => {
    dispatch(nextQuestion({roomId: id, roundIndex: gameAdmin.currentRound + 1, questionIndex: 0}));
  };

  return (
    <>
    {gameAdmin.loading && <Loading/>}
     <Layout className="admin-body">
      <Header
        className="admin-header"
      >
         <Box sx={{ flexGrow: 1 }}>
            <Tooltip title="Profile">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, float: "right" }}>
                    <Icon sx={{color: 'white', fontSize: 32}}>account_circle</Icon>
                </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px"}}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {/* <MenuItem key="home" onClick={handleGoHome}>
                  <Typography textAlign="center">Home</Typography>
              </MenuItem> */}
              <MenuItem key="logout" onClick={handleLogout}>
                  <Typography textAlign="center">LogOut</Typography>
              </MenuItem>
            </Menu>
          </Box>
      </Header>
      <Layout>
        <Sider
          // width={200}
          className="admin-sider"
        >
            <TextField
              className="white-text-field"
              id="outlined-read-only-input"
              variant="outlined"
              label="Number of Rounds"
              value={gameAdmin.roundLimit}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              className="white-text-field"
              id="outlined-read-only-input"
              label="Current Round"
              value={gameAdmin.currentRound + 1}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              className="white-text-field"
              id="outlined-read-only-input"
              label="Number of Questions"
              value={gameAdmin.currentRound >= 0 ? gameAdmin.questionLimit[gameAdmin.currentRound] : gameAdmin.questionLimit[0]}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              className="white-text-field"
              id="outlined-read-only-input"
              label="Current Question"
              value={gameAdmin.currentQuestion + 1}
              InputProps={{
                readOnly: true,
              }}
            />
        </Sider>
        <Layout
          style={{
            padding: '0 24px 24px',
          }}
        >
          <Content className="admin-content">
            <Typography variant="h5" className="question">
              {gameAdmin.question ? gameAdmin.question : "Question"}
            </Typography>
            <div className="question-image-div">
              <img src={gameAdmin.question_image} alt="Question img" className="question-image"/>
            </div>
            <div className="navigation">
              <Button
                variant="contained"
                color="warning"
                onClick={handleStart}
                disabled={gameAdmin.start}
              >
                Start
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNextQuestion}
                disabled = {(gameAdmin.currentQuestion + 1) === (gameAdmin.currentRound >= 0 ? gameAdmin.questionLimit[gameAdmin.currentRound] : gameAdmin.questionLimit[0])}
              >
                Next Question
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNextRound}
                disabled = {(gameAdmin.currentRound + 1) === gameAdmin.roundLimit}
              >
                Next Round
              </Button>
              <Button
                variant="contained"
                color="warning"
                onClick={handleEnd}
                disabled={!gameAdmin.start}
              >
                End
              </Button>
            </div>
          </Content>
        </Layout>
      </Layout>
    </Layout>
      
    </>

  );
};

export default AdminConsole;