import Cookies from "js-cookie";

export const deleteRole = (role) => {
  let roleValue = Cookies.get('role');
  let roleArray = [];
  if(roleValue) {
    roleArray = JSON.parse(roleValue);
  }
  const index = roleArray.indexOf(role);
  if (index > -1) { // only splice array when item is found
    roleArray.splice(index, 1); // 2nd parameter means remove one item only
  }
  Cookies.set('role', JSON.stringify(roleArray));
}