import React from 'react';
import { Spin } from 'antd';

const Loading = () => {
  return (
    <div style={{ 
      position: 'fixed', // Use fixed or absolute position
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(255, 255, 255, 0.5)', // Semi-transparent background
      zIndex: 1000 // Ensure it's above other content
    }}>
      <Spin size="large" />
    </div>
  );
};

export default Loading;