import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { successNotification, errorNotification } from "../../utils/routes/Notification";
import { checkDocumentId } from "../../apis/firebaseAPI";
import Cookies from 'js-cookie';

export const checkRoomId = createAsyncThunk(
  'valid/check_room_id',
  async({id}, {rejectWithValue}) => {
    try {
      const isExisting = await checkDocumentId("rooms", id);
      if(isExisting) {
        Cookies.set('roomId', id);
      } else {
        Cookies.set('roomId', id + '***');
        id += '***';
      }
      return {
        isExisting: isExisting,
        roomId: id
      }
    } catch (error) {
      const errorCode = error.code;
      console.log('Validate Error:', error);
      console.log('Validate ErrorCode:', errorCode);
      return rejectWithValue('Network Error');
    }
  }
)

const validSlice = createSlice({
  name: "valid",
  initialState: { user: "", login: false, status: "idle", roomId: Cookies.get('roomId') || "", error: null, loading: false },
  reducers: {
    initializeValidState: (state) => {
      state.loading = false;
      state.user = "";
      state.roomId = "";
      state.status = "idle";
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(checkRoomId.pending, (state) => {
      state.status = "loading";
      state.loading = true;
    })
    .addCase(checkRoomId.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.loading = false;
      if(action.payload.isExisting) {
        console.log(Cookies.get('roomId'));
        state.roomId = action.payload.roomId;
        successNotification("Success!", "Room ID Validated successfully!");
      } else {
        Cookies.set('roomId', action.payload.roomId + '***');
        state.roomId = action.payload.roomId;
        errorNotification("Error!", "There is no such room! Check your url then try again.");
      }
    })
    .addCase(checkRoomId.rejected, (state, action) => {
      state.status = "failed";
      state.loading = false;
      Cookies.set('roomId', 'neterror***');
      state.roomId = '';
      errorNotification("Error!", action.payload);
    })
  },
});

export const { initializeValidState } = validSlice.actions;

export default validSlice.reducer;