import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

if ("serviceWorker" in navigator) {
      navigator.serviceWorker
      .register("/firebase-messaging-sw.js", { scope: "/firebase-cloud-messaging-push-scope" })
      .then((registration) => {
        var serviceWorker;
        if (registration.installing) {
          serviceWorker = registration.installing;
          // console.log('Service worker installing');
        } else if (registration.waiting) {
          serviceWorker = registration.waiting;
          // console.log('Service worker installed & waiting');
        } else if (registration.active) {
          serviceWorker = registration.active;
          // console.log('Service worker active');
        }
  
        if (serviceWorker) {
          console.log("sw current state", serviceWorker.state);
          if (serviceWorker.state == "activated") {
            //If push subscription wasnt done yet have to do here
  
            console.log("sw already activated - Do watever needed here");
          }
          serviceWorker.addEventListener("statechange", function (e) {
            console.log("sw statechange : ", e.target.state);
            if (e.target.state == "activated") {
              console.log(
                "Just now activated. now we can subscribe for push notification"
              );
            }
          });
        }
      })
      .catch((error) => {
        console.error("Service Worker Registration Failed:", error);
      });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
