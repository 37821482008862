import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { checkIfUserIsAuthenticated } from '../functions/checkIfUserAuthenticated';
import { getRole } from '../functions/getRole';
import { checkRoomIdValid } from '../functions/checkRoomIdValid';
import { checkHasSameElement } from '../functions/checkHasSameElement';

const CaptainRoute = ({ children, allowedRoles }) => {
  const navigate = useNavigate();
  const roleArray = getRole();
  const {id} = useParams();
  const possibleRoles = allowedRoles.map((role) => role + "*" + id);

  useEffect(() => {
    const isValidRoom = checkRoomIdValid(id);
    const isAuthenticated = checkIfUserIsAuthenticated();
    const hasRequiredRole = checkHasSameElement(possibleRoles, roleArray);
    if (!isAuthenticated || !hasRequiredRole || !isValidRoom) {
      navigate(`/captain/login/${id}`);
    }
  }, [navigate, roleArray, possibleRoles, id]); // dependencies for useEffect

  // If the user isn't authenticated or doesn't have the required role, 
  // you might choose not to render the children until the effect completes.
  // Or, if you prefer, you can add additional logic to conditionally render the children.
  return children;
};

export default CaptainRoute;