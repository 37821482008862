import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { successNotification, errorNotification } from "../../utils/routes/Notification";
import Cookies from 'js-cookie';
import axios from 'axios';

import { serverUrl } from "../../config/config";
import { parseInfo } from "../../utils/functions/parseInfo";

export const startGame = createAsyncThunk(
  'admin/start_game',
  async({roomId}, {rejectWithValue}) => {
    try {
      const response = await axios.post(`${serverUrl}/admin/start_game`, {roomId: roomId, packageId: Cookies.get('packageId')});
      return response.data;
    } catch (error) {
      console.log('Start Game',error.code, error.message);
      return rejectWithValue(error.message);
    }
  }
)

export const getQuestion = createAsyncThunk(
  'admin/get_question',
  async({roomId}, {rejectWithValue}) => {
    try {
      const response = await axios.post(`${serverUrl}/admin/get_question`, {roomId: roomId});
      return response.data;
    } catch (error) {
      console.log('Get Question',error.code, error.message);
      return rejectWithValue(error.message);
    }
  }
)

export const nextQuestion = createAsyncThunk(
  'admin/next_question',
  async({roomId, roundIndex, questionIndex}, {rejectWithValue}) => {
    try {
      const response = await axios.post(`${serverUrl}/admin/next_question`, {roomId: roomId, info: roundIndex + "," + questionIndex, packageId: Cookies.get('packageId')});
      return {response: response.data, roundIndex: roundIndex, questionIndex: questionIndex};
    } catch (error) {
      console.log('Next Question',error.code, error.message);
      return rejectWithValue(error.message);
    }
  }
)

export const endGame = createAsyncThunk(
  'admin/end_game',
  async({roomId}, {rejectWithValue}) => {
    try {
      const response = await axios.post(`${serverUrl}/admin/end_game`, {roomId: roomId});
      return response.data;
    } catch (error) {
      console.log('Start Game',error.code, error.message);
      return rejectWithValue(error.message);
    }
  }
)


const gameAdminSlice = createSlice({
  name: "gameAdmin",
  initialState: { questionData:{}, status: "idle", error: null, loading: false, packageId: Cookies.get('packageId'), question:"", question_image: "", questionLimit: [0], roundLimit: 0, currentRound: -1, currentQuestion: -1, start: false,},
  reducers: {
    initializeAdminState: (state) => {
      state.roundLimit = 0;
      state.questionLimit = [0];
      state.currentRound = -1;
      state.currentQuestion = -1;
      state.question = "";
      state.question_image = "";
      state.packageId = "";
      state.status = "idle";
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(startGame.pending, (state) => {
      state.status = "loading";
      state.loading = true;
    })
    .addCase(startGame.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.loading = false;
      state.start = true;
      state.questionData = action.payload.question;
      state.question = action.payload.question.data.question;
      state.question_image = action.payload.question.data.question_image;
      state.questionLimit = action.payload.questionLimit;
      state.roundLimit = action.payload.roundLimit;
      state.currentRound = 0;
      state.currentQuestion = 0;
      // Cookies.set('questionLimit', JSON.stringify(action.payload.questionLimit));
      // Cookies.set('roundLimit', action.payload.roundLimit);
      // Cookies.set('currentRound', 0);
      // Cookies.set('currentQuestion', 0);
      // Cookies.set('start', true);
      successNotification("Success", "Game Start!");
    })
    .addCase(startGame.rejected, (state, action) => {
      state.status = "failed";
      state.loading = false;
      errorNotification("Error!", action.payload);
    })
    .addCase(getQuestion.pending, (state) => {
      state.status = "loading";
      state.loading = true;
    })
    .addCase(getQuestion.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.loading = false;
      state.start = action.payload.status;
      if(state.start) {
        state.questionData = action.payload.question;
        state.question = action.payload.question.data.question;
        state.question_image = action.payload.question.data.question_image;
        state.roundLimit = action.payload.roundLimit;
        state.questionLimit = action.payload.questionLimit;
        state.currentQuestion = parseInfo(action.payload.info).questionIndex;
        state.currentRound = parseInfo(action.payload.info).roundIndex;
  
        // Cookies.set('currentRound', state.currentRound);
        // Cookies.set('currentQuestion', state.currentQuestion);
        // Cookies.set('questionLimit', JSON.stringify(action.payload.questionLimit));
        // Cookies.set('roundLimit', action.payload.roundLimit);
      } else {
        state.roundLimit = 0;
        state.questionLimit = [0];
        state.currentRound = -1;
        state.currentQuestion = -1;
        state.question = "";
        state.question_image = "";
        state.packageId = "";
        state.status = "idle";
      }
      // successNotification("Success");
    })
    .addCase(getQuestion.rejected, (state, action) => {
      state.status = "failed";
      state.loading = false;
      errorNotification("Error!", action.payload);
    })
    .addCase(endGame.pending, (state) => {
      state.status = "loading";
      state.loading = true;
    })
    .addCase(endGame.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.loading = false;
      console.log(action.payload);
      state.start = false;
      state.roundLimit = 0;
      state.questionLimit = [0];
      state.currentRound = -1;
      state.currentQuestion = -1;
      state.question = "";
      state.question_image = "";
      state.packageId = "";
      state.status = "idle";
      successNotification("Success");
    })
    .addCase(endGame.rejected, (state, action) => {
      state.status = "failed";
      state.loading = false;
      errorNotification("Error!", action.payload);
    })
    .addCase(nextQuestion.pending, (state) => {
      state.status = "loading";
      state.loading = true;
    })
    .addCase(nextQuestion.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.loading = false;
      state.questionData = action.payload.response.question;
      state.question = action.payload.response.question.data.question;
      state.question_image = action.payload.response.question.data.question_image;

      state.currentRound = action.payload.roundIndex;
      state.currentQuestion = action.payload.questionIndex;
      // Cookies.set('questionLimit', JSON.stringify(action.payload.questionLimit));
      // Cookies.set('roundLimit', action.payload.roundLimit);
      // Cookies.set('currentRound', 0);
      // Cookies.set('currentQuestion', 0);
      // Cookies.set('start', true);
      successNotification("Success", "Next Question!");
    })
    .addCase(nextQuestion.rejected, (state, action) => {
      state.status = "failed";
      state.loading = false;
      errorNotification("Error!", action.payload);
    })
  },
});

export const {initializeAdminState} = gameAdminSlice.actions;

export default gameAdminSlice.reducer;