import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { checkIsEmptyRole } from '../functions/checkIsEmptyRole';

const AdminValidateRoute = ({ children }) => {
  const { id } = useParams();
  const roomId = Cookies.get('roomId');
  Cookies.set('attempt', 'admin');

  // If the ID in the cookie does not match the ID in the URL parameter,
  // redirect to the validate route
  if (roomId !== id) {
    if (!checkIsEmptyRole()) {
      return <Navigate to={`/invalid/other`} replace />;
    }
    return <Navigate to={`/validate/${id}`} replace />;
  }


  // If IDs match, render the children components
  return children;
};

export default AdminValidateRoute;
