import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./features/authSlice";
import validSlice from "./features/validSlice";
import gameAdminSlice from "./features/gameAdminSlice";
import gameCaptainSlice from "./features/gameCaptainSlice";
import gamePresentationSlice from "./features/gamePresentationSlice";

const store = configureStore({
    reducer: {
        auth: authSlice,
        valid: validSlice,
        gameAdmin: gameAdminSlice,
        gameCaptain: gameCaptainSlice,
        gamePresentation: gamePresentationSlice,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
        serializableCheck: false,
    })
});

export default store;
