import { Button, Result } from 'antd';
import { useNavigate, useParams } from 'react-router-dom'; // if you're using react-router
import './InvalidPage.css'

const CurrentInOtherRoom = () => {
  const {id} = useParams();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/"); // Implement go-back logic here
  };

  return (
    <div
      className = "invalid-body"
    >
      <Result
        status="error"
        title={<span style={{ color: '#fff' }}>Check Other Room Login Status</span>} // Ensure title text is also white
        subTitle={
          <span style={{ color: '#fff' }}>
            Sorry, you have already logged in other room and you can't enter this room.<br/>
            First, please log out from your current room.
          </span>
        }
        extra={[
          <Button type="primary" key="goBack" onClick={handleGoBack}>
            Go Back
          </Button>,
        ]}
      />
    </div>
  );
};

export default CurrentInOtherRoom;